<template>
  <div id="workflow-browse">
    <Portal to="action-bar">
      <keep-alive>
        <ItemActions
          v-if="!isLoading"
          item="workflow"
          :columns.sync="columns"
          :group-by.sync="groupBy"
          :order.sync="sortBy.order"
          :filter-by.sync="filterBy"
          :active-view.sync="activeView"
          :criteria.sync="sortBy.criteria"
          :simple-search-applyed.sync="simpleSearchApplyed"
          @refresh="getWorkflows"
          @search="searchRow"
          @update="getWorkflows"
          @export="export_Data"
        />
      </keep-alive>
    </Portal>

    <div v-if="!isLoading" class="content">
      <template v-if="totalItems">
        <ItemActionChips
          :sort-by="sortBy"
          :columns="columns"
          :group-by="groupBy"
          :filter-by="filterBy"
          :simple-search-applyed="simpleSearchApplyed"
          @clearSortBy="clearSortBy"
          @clearGroupBy="clearGroupBy"
          @clearFilterBy="clearFilterBy"
        />

        <WorkflowGrid
          v-if="activeView === 'GRID'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @edit="editWorkflow"
          @more="showWorkflowDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
          @info="copyWorkflow"
        />

        <WorkflowList
          v-if="activeView === 'LIST'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @edit="editWorkflow"
          @more="showWorkflowDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
          @info="copyWorkflow"
        />
      </template>
      <template v-else>
        <StateWrapper
          icon="o_account_tree"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>

    <Pagination
      v-if="!isLoading"
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
      @loadAll="loadAllWorkflows"
    />

    <WorkflowDetails
      v-model="isWorkflowDetailsVisible"
      :columns="columns"
      :workflow="selectedWorkflow"
      @edit="editWorkflow"
      @delete="showDeleteConfirmation"
    />

    <ConfirmDeleteWorkflow
      v-model="isDeleteConfirmationVisible"
      :workflow="selectedWorkflow"
      @delete="updateWorkflow"
    />

    <ConfirmRestoreWorkflow
      v-model="isRestoreConfirmationVisible"
      :workflow="selectedWorkflow"
      @restore="updateWorkflow"
    />
  </div>
</template>

<script>
import { startCase, capitalize } from "lodash-es";
import { workflow } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import WorkflowGrid from "./components/WorkflowGrid.vue";
import WorkflowList from "./components/WorkflowList.vue";
import WorkflowDetails from "./components/WorkflowDetails.vue";
import ConfirmDeleteWorkflow from "./components/ConfirmDeleteWorkflow.vue";
import ConfirmRestoreWorkflow from "./components/ConfirmRestoreWorkflow.vue";
import { exportData } from "@/helpers/export-excel-data";

export default {
  name: "WorkflowsBrowseAndTrash",

  components: {
    Portal,
    StateWrapper,
    ItemActionChips,
    ItemActions,
    WorkflowGrid,
    WorkflowList,
    WorkflowDetails,
    Pagination,
    ConfirmDeleteWorkflow,
    ConfirmRestoreWorkflow,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "initiatedBy",
          label: "Initiate Using",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          options: [
            {
              id: this.$nano.id(),
              label: "Document",
              value: "DOCUMENT",
            },
            {
              id: this.$nano.id(),
              label: "Document and Form",
              value: "DOCUMENT_FORM",
            },
            {
              id: this.$nano.id(),
              label: "Form",
              value: "FORM",
            },
          ],
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "status",
          label: "Status",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "flowStatus",
          label: "Status",
          type: "",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          options: [
            {
              id: this.$nano.id(),
              label: "Draft",
              value: "DRAFT",
            },
            {
              id: this.$nano.id(),
              label: "Published",
              value: "PUBLISHED",
            },
          ],
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
      ],
      workflows: [],
      workflowData: [],
      activeView: "GRID",
      selectedWorkflow: {},
      isWorkflowDetailsVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      isLoading: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      simpleSearchApplyed: false,
    };
  },

  computed: {
    items() {
      return this.workflows.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((workflow) => ({
          id: workflow.id,
          name: workflow.name,
          description: workflow.description,
          initiatedBy: startCase(capitalize(workflow.initiatedBy)),
          status: startCase(
            capitalize(
              JSON.parse(workflow.flowJson).settings.publish.publishOption
            )
          ),
          flowStatus: startCase(
            capitalize(
              JSON.parse(workflow.flowJson).settings.publish.publishOption
            )
          ),
          createdBy: workflow.createdBy,
          createdAt: this.$day.format(workflow.createdAt),
          modifiedBy: workflow.modifiedBy,
          modifiedAt: this.$day.format(workflow.modifiedAt),
          icon: "mdi-text-box",
          infoIcon: "mdi-content-duplicate",
          infoIconTooltip: "create duplicate workflow",
          infoIconColor: "grey",
        })),
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.defaultView();
      },
    },

    "$store.state.defaultView": function () {
      this.defaultView();
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getWorkflows(),
      { deep: true, immediate: true }
    );
  },

  methods: {
    defaultView() {
      let options = this.$store.state.defaultView;
      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      if (Object.keys(options).length) {
        if (options[menu]) {
          let view = options[menu].find((item) => item.subMenu === subMenu);
          if (view) {
            this.activeView = view.view;
          }
        }
      }
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) {
        this.filterBy = [];
      }
      this.getWorkflows();
    },

    findWorkflow(workflowId) {
      const workflows = [];
      this.items.forEach((item) => workflows.push(...item.data));
      return workflows.find((workflow) => workflow.id === workflowId);
    },

    showWorkflowDetails(workflowId) {
      this.selectedWorkflow = this.findWorkflow(workflowId);
      this.isWorkflowDetailsVisible = true;
    },

    editWorkflow(workflowId) {
      this.$router.push({
        name: "workflow-builder",
        params: { id: String(workflowId) },
      });
    },

    showDeleteConfirmation(workflowId) {
      this.selectedWorkflow = this.findWorkflow(workflowId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(workflowId) {
      this.selectedWorkflow = this.findWorkflow(workflowId);
      this.isRestoreConfirmationVisible = true;
    },

    loadAllWorkflows() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    searchRow(search) {
      if (search) {
        this.workflows = this.workflowData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.workflows = this.workflowData;
      }
    },

    /* api functions */

    async getWorkflows() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await workflow.getWorkflows({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;

      this.workflows = data || [];
      this.workflowData = data || [];
      this.totalItems = meta?.totalItems || 0;
      // data[0].value.forEach((row) => {
      //   if (row.flowJson) {
      //     JSON.parse(row.flowJson);
      //     console.log(
      //       startCase(
      //         capitalize(
      //           JSON.parse(row.flowJson).settings.publish.publishOption
      //         )
      //       )
      //     );
      //   } else {
      //     console.log(row.id);
      //     console.log(row.flowJson);
      //   }
      // });
    },

    async updateWorkflow(payload) {
      this.$store.commit("showLoadingBarPage");

      const { error } = await workflow.updateWorkflow(
        this.selectedWorkflow.id,
        payload
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const mode = payload.isDeleted ? "deleted" : "restored";
      const name = this.selectedWorkflow.name;
      this.$alert.success(`Workflow "${name}" ${mode} successfully`);

      this.getWorkflows();
    },

    async copyWorkflow(workflowId) {
      let copyWorkflow = this.workflows[0].value.find(
        (workflow) => workflow.id === workflowId
      );
      let copyWorkflowJson = JSON.parse(copyWorkflow.flowJson);
      copyWorkflowJson.settings.general.name += " - Copy";
      copyWorkflowJson.settings.publish.publishOption = "DRAFT";
      copyWorkflowJson.settings.general.initiateUsing.repositoryId = 0;
      const { error } = await workflow.createWorkflow(copyWorkflowJson);

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success("Duplicate workflow created");
      this.getWorkflows();
    },

    async export_Data() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await workflow.getWorkflows({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: 0,
        currentPage: 0,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { meta, data } = payload;
      if (meta.totalItems && data.length) {
        let exportRecords = [];
        data[0].value.forEach((workflow) => {
          exportRecords.push({
            Name: workflow.name,
            Description: workflow.description,
            "Initiated Mode": startCase(capitalize(workflow.initiatedBy)),
            Status: startCase(
              capitalize(
                JSON.parse(workflow.flowJson).settings.publish.publishOption
              )
            ),
            "Created By": workflow.createdBy,
            "Created At": this.$day.format(workflow.createdAt),
            "Last Modified By": workflow.modifiedBy,
            "Last Modified At": this.$day.format(workflow.modifiedAt),
          });
        });
        let exportName = "Workflow List - Report";
        if (this.mode === "TRASH") {
          exportName = "Workflow Trash List - Report";
        }
        exportData(exportRecords, exportName);
      } else {
        //this.$alert.info("No data found for export");
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#workflow-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
